@import '../scss/constants';

.error {
  background-color: $color-brand-dark-blue;
  white-space: pre-line;

  :global(.akorda-banner-message-title) {
    margin-top: 20px;
  }
}

:global(.intercom-launcher),
:global(.intercom-launcher-frame) {
  transform: scale(0.8) !important;
}
