@import '../../scss/constants';

.root {
  font-family: $font-family-base;
  font-weight: bold;
  font-size: $regular-font-size;
  letter-spacing: 1px;
  padding-right: $space-unit * 6;
  padding-left: $space-unit * 6;
  border-radius: 3px;
  cursor: pointer;
  text-transform: uppercase;

  &.isProcessing {
    display: flex;
    align-items: center;
    padding-left: 15px !important;
    padding-right: 15px !important;

    .buttonSpinner {
      margin-left: 5px;
    }
  }

  &:global(.btn-block) {
    &.isProcessing {
      justify-content: center;

      .buttonSpinner {
        margin-left: 20px;
      }
    }
  }

  &:global(.btn-primary),
  &:global(.btn-primary):focus {
    color: white;
    background-color: $color-risk-low;
    border: none;
  }

  &:global(.btn-primary):hover {
    color: white;
    background-color: $color-brand-light-blue;
    border: none;
  }

  &:global(.btn-secondary),
  &:global(.btn-secondary):focus {
    color: $color-txt-highlights-8;
    border: 1px solid $color-txt-highlights-8;
    background-color: transparent;
  }

  &:global(.btn-secondary):hover {
    color: $color-txt-highlights-9;
    border: 1px solid $color-txt-highlights-9;
    background-color: transparent;
  }

  &:global(.btn-primary.disabled),
  &:global(.btn-primary:disabled),
  &:global(.btn-secondary.disabled),
  &:global(.btn-secondary:disabled) {
    color: white;
    background-color: $color-UIGrey-4;
    border: none;

    &:hover {
      color: white;
      background-color: $color-UIGrey-4;
      border: none;
      cursor: not-allowed;
    }
  }

  &[data-size='lg'] {
    height: 48px;
  }

  &[data-size='md'] {
    height: 36px;
    min-width: 200px;
  }

  &[data-size='sm'] {
    height: 32px;
    min-width: 160px;
    font-size: $small-font-size;
  }

  &[data-width='auto'] {
    min-width: auto;
  }
}
