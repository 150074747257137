@import '../../../scss/constants';

:global(.akorda-button) {
  font-weight: bold;
  font-size: $regular-font-size;
  letter-spacing: 1px;
  border-radius: 3px;
  cursor: pointer;
  text-transform: uppercase;

  &.isProcessing {
    display: flex;
    align-items: center;
    padding-left: 15px !important;
    padding-right: 15px !important;

    .buttonSpinner {
      margin-left: 5px;
    }
  }

  &:global(.btn-block) {
    &.isProcessing {
      justify-content: center;

      .buttonSpinner {
        margin-left: 20px;
      }
    }
  }

  &:global(.btn-outline-primary) {
    color: $color-txt-highlights-8;

    &:hover {
      color: white;
      background-color: $color-txt-highlights-8;
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &:global(.btn-primary) {
    color: white;
    background-color: $color-risk-low;
    border: none;

    &:hover {
      color: white;
      background-color: $color-brand-light-blue;
      border: none;
    }
  }

  &:global(.btn-secondary) {
    color: $color-txt-highlights-8;
    border: 1px solid $color-txt-highlights-8;
    background-color: transparent;

    &:hover {
      color: $color-txt-highlights-9;
      border: 1px solid $color-txt-highlights-9;
      background-color: transparent;
    }
  }

  &:global(.btn-danger) {
    color: $color-risk-high;
    background-color: white;
    border: 1px solid $color-risk-high;

    &:hover {
      border: 1px solid $color-txt-highlights-1;
      background-color: transparent;
    }
  }

  /* Disabled button styles */
  &:global(.btn-primary.disabled),
  &:global(.btn-primary:disabled),
  &:global(.btn-secondary.disabled),
  &:global(.btn-secondary:disabled),
  &:global(.btn-danger.disabled),
  &:global(.btn-danger:disabled) {
    color: white;
    background-color: $color-UIGrey-4;
    border: none;

    &:hover {
      color: white;
      background-color: $color-UIGrey-4;
      border: none;
      cursor: not-allowed;
    }
  }
}
