@import '../../../scss/constants';

.root {
  background: $color-brand-dark-blue;
  color: $color-bg-white;
  height: $header-height;

  .navBar {
    display: flex;
    align-items: center;
    padding: 0 10px;
    width: 100%;
    height: 100%;

    :global(.navbar-nav) {
      margin-top: 3px;
    }
  }

  .headerLogo {
    cursor: pointer;
    margin-right: 25px;

    svg {
      width: 215px;
    }
  }
}

.smallHeader {
  height: 32px;
}

.appLogo {
  width: auto;

  svg * {
    fill: #fff;
  }
}

@media only screen and (max-width: 1100px) {
  .headerLogo .productLogo {
    display: none;
  }
}
