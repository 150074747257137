@import '../../scss/constants';

.bannerMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;

  .logo {
    margin: 80px 0 75px;

    svg {
      height: 30px;
    }
  }

  .messageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 15px;
    max-width: 728px;
    width: 100%;
    height: 300px;
    min-height: 300px; // for ie 11

    :global(.font-icon) {
      svg {
        width: 50px;
        height: 50px;
      }
    }
  }

  :global(.fa-circle-check) {
    color: $color-brand-green;
  }

  :global(.fa-triangle-exclamation) {
    color: $color-brand-blue;
  }

  :global(.fa-circle-exclamation) {
    color: $error-color;
  }
}

@media screen and (max-width: 1200px) {
  .formContainer {
    padding-right: $space-unit * 10;
    padding-left: $space-unit * 10;
  }
}

@media screen and (max-width: 900px) {
  .formContainer {
    padding-right: $space-unit * 6;
    padding-left: $space-unit * 6;
  }
}

@media screen and (max-width: 720px) {
  .formContainer {
    padding-right: $space-unit * 2;
    padding-left: $space-unit * 2;
  }
}

.title {
  color: #1c1c43;
  font-family: $font-family-light;
  font-size: 28px;
  line-height: 35px;
  width: 100%; // added for ie11
  margin: 10px 0 0;
  text-align: center;
}

.topIcon {
  width: 60px;
  height: 60px;
}

.button {
  margin-top: 32px;
  width: 320px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.secondary {
  margin-left: 15px;
}

.description {
  font-size: 14px;
  width: 50%;
  text-align: center;
  margin-top: 10px;
}
