@import './scss/constants';

body {
  margin: 0;
  font-family: Lato, Montserrat-Light, HelveticaNeue-Light, 'Segoe UI', sans-serif;
}

#app {
  color: $color-UIGrey-dark-blue;
  background-color: white;
}
