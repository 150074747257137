@import '../../scss/constants';

.icon {
  display: inline-block;

  &.sm {
    height: 16px;
    width: 16px;
    font-size: inherit;
  }

  &.md {
    height: 24px;
    width: 24px;
    font-size: 24px;
  }

  &.lg {
    height: 36px;
    width: 36px;
    font-size: 26px;
  }

  i {
    font-size: inherit;
  }
}
