@import '../../../scss/constants';

.accessDenied {
  align-items: center;
  background-color: $color-brand-dark-blue;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 150px;

  h1 {
    margin-bottom: 2rem;
  }

  button {
    margin-top: 2rem;
  }
}
