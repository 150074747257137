@import '../../scss/constants';

.notificationContainer {
  width: 450px !important;

  :global(.akorda-toast) {
    background-image: url('../../media/img/background.svg');
    background-position: 50% 0;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid $color-UIGrey-6;
    border-radius: 7px;
  }

  :global(.akorda-toast-success) {
    .icon {
      color: $color-brand-light-green;
    }
  }

  :global(.akorda-toast-warning) {
    .icon {
      color: $color-risk-medium;
    }
  }

  :global(.akorda-toast-error) {
    .icon {
      color: $error-color;
    }
  }

  :global(.akorda-toast-close) {
    color: white;
  }
}

.notification {
  display: flex;
  align-items: center;
  white-space: pre-line;
  color: white;

  .icon {
    font-size: 30px;
    margin-right: 15px;
  }

  .body {
    color: white;
  }
}
