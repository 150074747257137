/*
* Font Family
* ======================================================================== */

$font-family-base: 'Lato', 'Montserrat-Light', 'HelveticaNeue-Light', 'Segoe UI', sans-serif;
$font-family-bold: 'Lato-Bold', 'Montserrat-Light', 'HelveticaNeue-Light', 'Segoe UI', sans-serif;
$font-family-light: 'Lato-Light', 'Montserrat-Light', 'HelveticaNeue-Light', 'Segoe UI', sans-serif;
$font-awesome: 'Font Awesome 5 Pro';
$font-contract: georgia, 'Times New Roman', times, serif;

/*
* Font Sizes
* ======================================================================== */

$tiny-font-size: 10px;
$small-font-size: 12px;
$regular-font-size: 14px;
$basic-font-size: 16px;
$large-font-size: 20px;
$banner-font-size: 40px;
$modal-title-font-size: 28px;

/*
* Colors
* ======================================================================== */
// BRAND COLORS
$color-brand-legalsifter: #595194;
$color-brand-dark-blue: #1c1c43;
$color-brand-blue: #292d78;
$color-brand-light-blue: #005899;
$color-brand-green: #51af46;
$color-brand-purple: #4f4789;
$color-brand-light-green: #9ec73d;
$color-bg-white: #fff;

// UI Greys
$color-UIGrey-dark-blue: #1c1c43;
$color-UIGrey-1: #4c5670;
$color-UIGrey-2: #8b91a3;
$color-UIGrey-3: #8b91a3;
$color-UIGrey-4: #c9ccd4;
$color-UIGrey-5: #e4e5e9;
$color-UIGrey-6: #edf0f2;
$color-UIGrey-7: #f6f8fa;
$color-UIGrey-8: rgb(246 248 250 / 0.5);

// Text Highlights
$color-txt-highlights-1: #aa1e30;
$color-txt-highlights-2: #e3495d;
$color-txt-highlights-3: #fcebed;
$color-txt-highlights-4: #976700;
$color-txt-highlights-5: #f3b83b;
$color-txt-highlights-6: #fff7e0;
$color-txt-highlights-7: #ebf6ff;
$color-txt-highlights-8: #0174ca;
$color-txt-highlights-9: #005899;
$color-txt-highlights-10: #ff8c00;

// RISK
$color-risk-none: #51af46;
$color-risk-low: #0174ca;
$color-risk-medium: #f3b83b;
$color-risk-high: #e3495d;
$color-success: #dcefda;
$error-color: #d9534f;
$color-row-hover: #fbfcfd;
$color-hubspot: #ff5c35;
$color-hubspot-secondary: #ff7a59;

// NEGOTIATION STATUS
$templateCreatedColor: #0174ca;
$inReviewColor: #005899;
$withCounterpatyColor: #f29f05;
$forSignatureColor: #94bf00;
$awaitingSignatureColor: #94bf00;
$awaitingApprovalColor: #005899;
$executedColor: #51af46;
$canceledColor: #8b91a3;
$filedColor: #4c5973;

// Search highlighting
$searchHit: #f8ea8c;
$searchHitFocus: #a4e8e0;

// Recommended highlighting
$recommended: #ecfff5;

// filtering
$inactiveFilterValueColor: #28791f;

// Comment color
$comment-color: cornsilk;
$comment-color-selected: rgb(231 225 200 / 1);
$color-bg-final: rgb(81 175 70 / 0.1);
$contractPreviewBackgroundColor: #f8f9fa;

/*
* Layout
* ======================================================================== */
$space-unit: 5px;
$small-header-height: 34px;
$footer-height: 72px;
$footer-complete-height: 79px;
$editor-toolbar-height: 32px;
$header-height: 50px; // height of the app header (navigation bar at the very top of every page)
$sub-header-height: 75px; // height of page headers below the app header

$modal-title-height: 100px;

:global(.flex-spring) {
  flex: 1;
}

/*
* Font
* ======================================================================== */
$font-size-title: 1.125em;

/*
* Media queries breakpoints
* ======================================================================== */

$screen-xs-min: 480px; /* Extra small screen / phone */
$screen-sm-min: 768px; /* Small screen / tablet */
$screen-md-min: 992px; /* Medium screen / desktop */
$screen-lg-min: 1200px; /* Large screen / wide desktop */
