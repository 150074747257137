@import '../../scss/constants';

.spinnerContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgb(255 255 255 / 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .message {
    font-family: $font-family-base;
    margin-top: 10px;
    font-size: $basic-font-size;
  }
}

.spinner {
  animation: rotating 1s linear infinite;
  width: 60px;

  &.small {
    width: 24px;
  }

  &.xsmall {
    width: 15px;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
